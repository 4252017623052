/** @jsx jsx */
import { jsx } from "theme-ui"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"

const IndexPage = () => (
  <Layout
    title="Contatti"
    subtitle="Apprezziamo le tue domande, dubbi e feedback."
  >
    <SEO title="Contatti" />
    <Container sx={{ p: [4, null] }}>
      <h1>Vuoi scriverci? </h1>
      <p>
        Email -{" "}
        <a href="mailto:idee@arturolorenzoni.it">idee@arturolorenzoni.it</a>
      </p>
      <h1>Sei una/un giornalista?</h1>
      <p>
        Email -{" "}
        <a href="mailto:media@arturolorenzoni.it">media@arturolorenzoni.it</a>
      </p>
    </Container>
  </Layout>
)

export default IndexPage
